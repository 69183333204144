import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='About Us' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='OVERVIEW' subtitle='Check out our company story and work process' />

            <p>Orama Property Developers (OPD) is a fully integrated

              property development and services company that was
              established with a strong desire to offer a panorama,
            </p>
            <p>complete package in property development</p> 
            <Heading title='HERITAGE' subtitle='Check out our company story and work process' />
            <p>Orama was established more than a decade ago and has
gone from strength to strength since. The mandate is to
create value and transform properties in a dynamic,
efficient and effective way beyond clients’ expectation.
Over the years we have grown and incoporate business
synergies and networks resulting in development of
bigger space into totally new residential suburbs namely;
Sunview Park and Ashdown Park.</p>

            <button className='btn2'>More About Us</button>
          </div>
          <div className='right row'>
            <img src='./immio.jpg' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
