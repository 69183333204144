import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          {/* Company Name and Logo */}
          <div className="footer-logo">
            
            <h1>Orama Property Developers</h1>
          </div>

          {/* Contact Details Section */}
          <div className="footer-content">
            <div className="footer-details">
              <h2>Contact Us</h2>
              <p>12 Chataway Road</p>
              <p>Hillside, Harare</p>
              <p>Telephone:</p>
              <ul>
                <li>+263 242 748 256</li>
                <li>+263 772 670 505</li>
                <li>+263 772 725 238</li>
              </ul>
              <p>
                Email: <a href="mailto:oramaproperty132@gmail.com">info@oramaproperties.co.zw</a>
              </p>
            </div>
          </div>

          {/* Useful Links Section */}
          <div className="footer-content">
            <div className="useful-links">
              <h2>Useful Links</h2>
              <ul>
                <li><a href="#sale">Property For Sale</a></li>
                <li><a href="#rent">Property To Rent</a></li>
                <li><a href="#showdays">Property Showdays</a></li>
                <li><a href="#contact">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>

        {/* Legal Section */}
        <div className="legal">
          <span>© 2024 Orama Property Developers</span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
