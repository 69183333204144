export const nav = [
  {
    text: "for sale",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "showdays",
    path: "/services",
  },
  {
    text: "blog",
    path: "/blog",
  },
 
  {
    text: "contact us",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Family House",
    total: "10 Property",
  },
 
  {
    cover: "../images/hero/h3.png",
    name: "Apartment",
    total: "3 Property",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Office & Studio",
    total: "8 Property",
  },
 
]
export const list = [
  {
    id: 1,
    cover: "../images/list/p-1.jpg",
    name: "sun city  villa",
    location: "20 hawick road pomona",
    category: "For sale",
    price: "$360 000-$400 000",
    SPECS: "5 bedrooms",
    SPECS2:"2 bathrooms 1 upstairs and 1 downstairs ",
    SPECS3:"Kitchen 1 bedroom downstairs 4 bedrooms upstairs ",
    SPECS4:"1 ensuite Lounge",
    type: "Apartment",
  },
  {
    id: 2,
    cover: "../images/list/p-2.jpg",
    name: "sun city  villa",
    location: "20 hawick road pomona",
    category: "For sale",
    price: "$360 000-$400 000",
    SPECS: "5 bedrooms",
    SPECS2:"2 bathrooms 1 upstairs and 1 downstairs ",
    SPECS3:"Kitchen 1 bedroom downstairs 4 bedrooms upstairs ",
    SPECS4:"1 ensuite Lounge",
    type: "Apartment",
  },
  {
    id: 3,
    cover: "../images/list/p-3.jpg",
    name: "sun city  villa",
    location: "20 hawick road pomona,",
    category: "For sale",
    price: "$360 000-$400 000",
    SPECS: "5 bedrooms",
    SPECS2:"2 bathrooms 1 upstairs and 1 downstairs ",
    SPECS3:"Kitchen 1 bedroom downstairs 4 bedrooms upstairs ",
    SPECS4:"1 ensuite Lounge",
    type: "Apartment",
  },
  {
    id: 4,
    cover: "../images/list/p-4.jpg",
    name: "sun city  villa",
    location: "20 hawick road pomona",
    category: "For sale",
    price: "$360 000-$400 000",
    SPECS: "5 bedrooms",
    SPECS2:"2 bathrooms 1 upstairs and 1 downstairs ",
    SPECS3:"Kitchen 1 bedroom downstairs 4 bedrooms upstairs ",
    SPECS4:"1 ensuite Lounge",
    type: "Apartment",
  },

]

export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
]
export const location = [
  {
    id: 1,
    name: "zimbabwe, harare",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "zimbabwe, harare",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-2.png",
  },
  {
    id: 3,
    name: "zimbabwe, harare",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-3.png",
  },
 
]
export const team = [
  {
    listings: "Managing Director",
    cover: "../images/customer/OIP.jpg",
    address: "zimbabwe, harare",
    name: "JAMES EVANS ",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },

  
  {
    listings: "Engineer",
    cover: "../images/customer/OIP.jpg",
    address: "zimbabwe, harare",
    name: "LEON ZVAREVASHE",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  
  
]
export const price = [
  {
    plan: "Basic",
    price: "29",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "49",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "79",
    ptext: "2 user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "USEFUL LINKS",
    text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Service Page" }, { list: "Property Page" }, { list: "Contact Page" }],
  },
  
  
]
